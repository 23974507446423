'use client';

import { StoreBranchApi } from '@/api-client/store-branch';
import { LOCAL_LOCATION } from '@/constants';
import useLocationStore from '@/store/locationStore';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Modal } from 'antd';
import clsx from 'clsx';
import { MapPin } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface ILocationDropdownProps {
  className?: {
    root: string;
    text: string;
  };
  fillIcon?: string;
}

const LocationDropdown = (props: ILocationDropdownProps) => {
  const { className, fillIcon = '#1d3f75' } = props;
  const [branches, setBranches] = useState<any[]>([]); // Initialize state with empty array
  const [selectedBranch, setSelectedBranch] = useState<any>(null); // Set initial selected branch
  const [modalOpen, setModalOpen] = React.useState(false);

  const { location: locationStore } = useLocationStore();

  useEffect(() => {
    if (locationStore?.name) {
      console.log('locationStore', locationStore?.name);
      setSelectedBranch(locationStore?.name);
    }
  }, [locationStore]);

  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === 'location_id') {
      // If location_id changes, update the selectedBranch
      const updatedLocationId = localStorage.getItem('location_id');
      if (updatedLocationId) {
        const updatedBranch = branches.find(branch => branch._id === updatedLocationId);
        if (updatedBranch) {
          setSelectedBranch(updatedBranch);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    const fetchBranches = async () => {
      try {
        // Fetch branches from API
        const response = await StoreBranchApi.getAll();
        const branchesData = response ?? []; // Fallback to empty array if response is null or undefined
        setBranches(branchesData);

        // Check if a location is saved in localStorage
        const savedLocationId = LOCAL_LOCATION;

        if (savedLocationId) {
          // If user has selected a location before, load it
          const savedBranch = branchesData.find(branch => branch._id === savedLocationId);
          if (savedBranch) {
            setSelectedBranch(savedBranch);
          }
        } else {
          // If no saved location, calculate the nearest branch
          const { latitude, longitude } = await getBrowserLocation();

          const nearestBranch = branchesData.reduce((nearest, branch) => {
            const branchCoords = getBranchCoordinates(branch.name);
            const distanceToBranch = calculateDistance(latitude, longitude, branchCoords.lat, branchCoords.lng);

            if (!nearest || distanceToBranch < nearest.distance) {
              return { branch, distance: distanceToBranch };
            }

            return nearest;
          }, null);

          if (nearestBranch) {
            localStorage.setItem('location_id', nearestBranch.branch._id); // Store nearest branch ID in localStorage
            setSelectedBranch(nearestBranch.branch); // Set nearest branch as selected
          }
        }
      } catch (error) {
        console.error('Error fetching branches or user location:', error);
      }
    };

    fetchBranches();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Return loading state while fetching to avoid mismatch
  if (!branches.length) return null; // Avoid rendering mismatched content

  const getBrowserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          error => {
            reject(error);
          },
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  const getBranchCoordinates = branchName => {
    const coordinates = {
      'Flower Mound': { lat: 33.00556, lng: -97.06995 },
      Mansfield: { lat: 32.5632, lng: -97.1417 },
      Frisco: { lat: 33.1507, lng: -96.8236 },
    };
    return coordinates[branchName];
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = x => (x * Math.PI) / 180;
    const R = 6371; // Radius of the earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon1 - lon2);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Create dropdown menu items with branch names
  const items: MenuProps['items'] = branches.map(branch => ({
    key: branch._id,
    label: (
      <div>
        <strong>{branch.name}</strong>
        <div className='text-xs text-gray-500'>{branch.address}</div>
      </div>
    ),
    onClick: () => {
      setSelectedBranch(branch); // Set selected branch on click
      localStorage.setItem('location_id', branch._id); // Store selected branch ID in localStorage
      window.location.reload(); // Reload the page to reflect changes
    },
  }));

  return (
    <div className={clsx('flex w-full items-center justify-end bg-zelene-blue', className?.root)}>
      <div className='flex items-center gap-2'>
        {/* <span className={clsx('text-sm text-white', className?.text)}>Location:</span> */}

        <Modal
          classNames={{
            body: 'flex flex-col gap-2',
            content: '!bg-[#FFF4E4]',
            header: '!bg-[#FFF4E4]',
          }}
          centered
          closable={false}
          footer={[]}
          open={!selectedBranch || modalOpen}
          title={<div className='font-[wise] !text-[28px] font-medium text-zelene-dark-blue'>Store near you</div>}
        >
          {branches.map((branch, index) => (
            <div
              key={index}
              className='flex cursor-pointer justify-center rounded-xl bg-gray-50 p-4 first:mt-6'
              onClick={() => {
                setSelectedBranch(branch); // Set selected branch on click
                localStorage.setItem('location_id', branch._id); // Store selected branch ID in localStorage
                window.location.reload(); // Reload the page to reflect changes
              }}
            >
              <div className='flex flex-col justify-center'>
                <strong className='text-center font-hneu text-[17px] font-medium text-zelene-blue'>
                  {branch.name}
                </strong>
                <div className='font-hneu text-sm text-zelene-grey'>{branch.address}</div>
              </div>
            </div>
          ))}
        </Modal>

        {/* Location Dropdown */}
        <Dropdown menu={{ items }} trigger={['click']} placement='bottom'>
          <span className='flex cursor-pointer items-center text-sm font-medium text-white'>
            {selectedBranch ? (
              <div className='group flex cursor-pointer flex-col rounded-2xl border border-zelene-yellow px-3 py-1 sm:border-none sm:py-0'>
                <div className='flex items-center justify-between gap-1 font-hneu text-zelene-grey *:text-[12px] group-hover:text-zelene-red'>
                  <span>Current location</span>
                  <DownOutlined />
                </div>

                <div
                  className={clsx(
                    'flex items-start gap-2 font-hneu font-semibold text-zelene-blue *:text-sm *:text-white',
                    className?.text,
                  )}
                >
                  <MapPin fill={fillIcon} className='size-4 group-hover:!text-zelene-red' />
                  <div className='!text-white group-hover:!text-zelene-red'>{selectedBranch.name}</div>
                </div>
              </div>
            ) : (
              <div className='flex flex-col justify-center gap-2'>
                Select a location
                <DownOutlined className='ml-2' />
              </div>
            )}
            {/* <DownOutlined className='ml-2' /> */}
          </span>
        </Dropdown>
      </div>
    </div>
  );
};

export default LocationDropdown;
