'use client';
import axiosClient from '@/helper/call-center';
import { formatTimeWithAMPM, imgUrlTransferV1 } from '@/utils/global-func';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Image, { StaticImageData } from 'next/image';
import LocationCover from '../../../../public/images/Booking/spa-massage.png';
import Location2 from '../../../../public/images/Location/flowermound.webp';
import Location3 from '../../../../public/images/Location/frisco.webp';
import Location1 from '../../../../public/images/Location/mansfield.webp';

dayjs.extend(dayLocaleData);
dayjs.extend(utc);
dayjs.extend(timezone);

interface IServiceCartProps {
  service: any;
  onRemove: any;
  totalPlus: any;
  dateTime: any;
  startTime: any;
}
const ServiceCart = (props: IServiceCartProps) => {
  const { service, onRemove, totalPlus, dateTime, startTime } = props || {};
  const { name, price, duration } = service || {};

  const serviceDetail = useQuery({
    queryKey: ['service-details', service?._id],
    queryFn: async () => {
      try {
        const result: any = await axiosClient.get(`/service/by-id/${service?._id}`);

        return result;
      } catch (error) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const serviceLocation = serviceDetail?.data?.data?.branch?.name;
  const getImageSource = (locationName: string): StaticImageData | string => {
    const imageMap: Record<string, StaticImageData | string> = {
      Mansfield: Location1,
      'Flower Mound': Location2,
      Frisco: Location3,
    };

    // Default image if locationName is not in the map
    return imageMap[locationName] || LocationCover; // Fallback is a string
  };
  console.log('serviceservice', service);

  return (
    <div>
      <div className='flex flex-col gap-8 sm:flex-row'>
        <Image
          src={imgUrlTransferV1(service.image)}
          width={130}
          height={130}
          alt='cart service'
          className='h-[240px] w-full rounded-lg object-cover shadow-sm sm:h-[136px] sm:flex-[1.5]'
        />

        <div className='flex flex-col sm:flex-[2]'>
          <div className='font-hneu text-[24px] font-bold text-zelene-dark-blue'>{name}</div>
          <div className='flex flex-col font-hneu text-[18px] font-medium text-zelene-dark-blue sm:flex-row sm:items-center sm:justify-between'>
            {serviceLocation}
          </div>

          <div>
            <span className='font-hneu text-[14px] font-medium text-zelene-dark-blue'>
              {dayjs(startTime).tz('America/Chicago').format('MM/DD')}
            </span>
            <span> - </span>
            <span className='font-hneu text-[14px] font-medium text-zelene-dark-blue'>
              {formatTimeWithAMPM(dayjs(startTime).tz('America/Chicago').format('HH:mm'))}
            </span>
          </div>

          <div className='flex items-center gap-1.5'>
            <span className='font-hneu text-[20px] font-bold text-zelene-red'>${price}&nbsp;USD</span>
            <span className='size-1.5 rounded-full bg-gray-400'></span>
            <span className='font-hneu text-sm leading-[21px] text-zelene-grey'>{duration}&nbsp;mins</span>
          </div>

          <div
            onClick={onRemove}
            className='flex w-full flex-1 cursor-pointer justify-end font-hneu text-base font-medium text-zelene-yellow underline'
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCart;
