import { useCart } from '@/hooks/cart.hook';
import { CartListProps } from '@/interface/cart';
import React from 'react';
import CartItem from './CartItem';

const CartList: React.FC<CartListProps> = ({ listCart, onRemove, location }) => {
  const { deleteCartMemberMutation } = useCart();

  return (
    <>
      {listCart?.map((cart: any) => {
        return (
          <CartItem
            showAddonBtn={true}
            key={cart._id}
            cart={cart}
            // onRemove={() => onRemove(cart._id, locationId)}
            onRemove={() => deleteCartMemberMutation.mutate(cart._id)}
          />
        );
      })}
    </>
  );
};

export default CartList;
